export const LOADED_LEAD = "loaded_lead";
export const UPDATED_LEAD = "updated_lead";
export const LOADED_INITIAL_INFO = "loaded_initial_info";
export const SET_MAGIC_LINK = "set_magic_link";
export const UPDATE_CURRENT_LEAD = "update_current_lead";
export const ADD_CART_ITEM = "add_cart_item";
export const RELOAD_LEAD_FILES = "reload_lead_files";
export const SEND_CONFIRMATION_FINISHED = "send_confimation_finished";
export const SHOW_CONFIRMATION_SCREEN = "show_confirmation_screen";
export const SHOW_HIDE_FILLED = "show_hide_filled";
export const UPDATE_LEAD_ID = "update_lead_id";
export const REDIRECT_TO_WEBSHOP = "redirect_to_webshop";
export const REDIRECT_TO_WEBSHOP_STARTED = "redirect_to_webshop_started";
export const REDIRECT_TO_WEBSHOP_ENDED = "redirect_to_webshop_ended";
export const SELECT_STEP = "select_step";
export const GOOGLE_ADDRESS = "google_address";
export const IS_VALID_VAT = "is_valid_vat";
export const VALIDATING_VAT = "validating_vat";

// Home page types
export const GET_PRODUCT_LIST = "get_product_list";
export const GET_PRODUCT_LIST_SUCCESS = "get_product_list_success";
export const GET_PRODUCT_LIST_ERROR = "get_product_list_error";
export const GET_PRODUCT_DETAILS = "get_product_data";
export const GET_PRODUCT_DETAILS_SUCCESS = "get_product_data_success";
export const GET_PRODUCT_DETAILS_ERROR = "get_product_data_error";
export const GET_CATEGORY_LIST = "get_category_list";
export const GET_CATEGORY_SUCCESS = "get_category_list_successs";
export const GET_CATEGORY_ERROR = "get_category_error";

//Marktdaten
export const CHANGE_INITIAL_DISPLAY = "change_initial_display";
export const SET_MATERIAL_DATA = "set_material_data";
export const SET_NAME = "set_name";
export const UPDATE_PRICE_CHART = "update_price_chart";
export const SET_GRAPH_FILTER = "set_graph_filter";
export const FILTER_GRAPH_DATA = "filter_graph_data";
