import { getDevice } from "../utils"

const checkAnalytics = () => {
  if (typeof window !== "undefined" && window.analytics) {
    return true
  }
  console.warn("No Segment Analytics found")
  return false
}

export const saveDistinctId = () => {
  if (checkAnalytics()) {
    const params = new URLSearchParams(window.location.search)
    const distinctId = params.get("distinctId")
    let ajs_user_id
    if (typeof localStorage !== "undefined") {
      ajs_user_id = localStorage["ajs_user_id"]
      if (ajs_user_id) {
        ajs_user_id = ajs_user_id.replaceAll('"', "")
      }
    }

    if (distinctId && distinctId !== ajs_user_id) {
      window.analytics.identify(distinctId)
    } else if (localStorage["ajs_user_id"] === "null") {
      let ajs_anonymous_id = localStorage["ajs_anonymous_id"]
      if (ajs_anonymous_id) {
        ajs_anonymous_id = ajs_anonymous_id.replaceAll('"', "")
        window.analytics.identify(ajs_anonymous_id)
      }
    }
  }
}

export const TrackEvent = (eventName, eventProperties) => {
  const device = getDevice()

  if (checkAnalytics()) {
    return window.analytics.track(eventName, {
      ...eventProperties,
      $device: device,
    })
  }
  return console.log(`Event triggered: ${eventName}:`, {
    ...eventProperties,
    $device: device,
  })
}
