/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
const i18next = require("i18next")
const { initReactI18next } = require("react-i18next")

require("./src/styles/global.css")
// You can delete this file if you're not using it
export { default as wrapRootElement } from "./src/pages/ReduxWrapper"

i18next.use(initReactI18next).init({
  resources: {
    de: {
      translation: require("./locales/seo.json"),
    },
  },
  lng: "de",
  fallbackLng: "de",
  interpolation: {
    escapeValue: false,
  },
})
