/* eslint-disable indent */
import { isArray } from "lodash";
import {
  GET_PRODUCT_LIST,
  GET_PRODUCT_LIST_SUCCESS,
  GET_PRODUCT_LIST_ERROR,
  GET_CATEGORY_LIST,
  GET_CATEGORY_SUCCESS,
  GET_CATEGORY_ERROR,
  GET_PRODUCT_DETAILS,
  GET_PRODUCT_DETAILS_SUCCESS,
  GET_PRODUCT_DETAILS_ERROR,
} from "../action/types";

const INITIAL_STATE = {
  productList: [],
  productData: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_PRODUCT_LIST:
      return {
        ...state,
        isLoadingProducts: true,
      };
    case GET_PRODUCT_LIST_SUCCESS:
      return {
        ...state,
        productList: transformProductList(action.payload),
        rawProductList: action.payload,
        isLoadingProducts: false,
      };
    case GET_PRODUCT_LIST_ERROR:
      return { ...state, error: action.payload, isLoadingProducts: false };
    case GET_CATEGORY_LIST:
      return {
        ...state,
        isLoadingCategories: true,
      };
    case GET_CATEGORY_SUCCESS:
      return {
        ...state,
        categoryList: action.payload,
        isLoadingCategories: false,
      };
    case GET_CATEGORY_ERROR:
      return { ...state, error: action.payload, isLoadingCategories: false };
    case GET_PRODUCT_DETAILS:
      return {
        ...state,
        isLoadingCategorData: true,
      };
    case GET_PRODUCT_DETAILS_SUCCESS:
      return {
        ...state,
        productData: transformProductData(action.payload),
        isLoadingProductData: false,
      };
    case GET_PRODUCT_DETAILS_ERROR:
      return { ...state, loadingProductData: false, error: action.payload };
    default:
      return state;
  }
};

// Transfor initial data
function transformProductList(data) {
  if (isArray(data) && data.length) {
    return data.map(product => ({
      title: product.name,
      id: product.id,
    }));
  }

  return [];
}

function transformProductData(data) {
  const product = data[0];

  return {
    price: product.price,
    range: `${product.price[0].price}-${product.price[2].price}`,
    minimum: product.price[0].start,
  };
}
