import { combineReducers } from "redux";
import LeadReducer from "./LeadReducer";
import HomePageReducer from "./HomePageReducer";
import MarktdatenReducer from "./MarktdatenReducer";

export default combineReducers({
  leadForm: LeadReducer,
  products: HomePageReducer,
  marktdaten: MarktdatenReducer,
});
